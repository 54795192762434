import './style.scss'
import { Dropdown, MenuProps } from 'antd'
import { FC, memo } from 'react'
import { Loginout } from '@/assets/svg/loginout'
import { Password } from '@/assets/svg/password'
import { UserStore } from '@/global-states'
import { useNavigate } from 'react-router-dom'

interface IProps {
  title?: string
}

const LayoutHeader: FC<IProps> = (props) => {
  const navigate = useNavigate()
  const loginOut = () => {
    UserStore.loginOut()
  }

  const items: MenuProps['items'] = [
    // {
    //   key: '5',
    //   label: (
    //     <a href="javascript:void(0)" onClick={() => navigate('/reset/password')}>
    //       <Password />
    //       修改密码
    //     </a>
    //   )
    // },
    {
      key: '4',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={loginOut}>
          <Loginout />
          退出登录
        </a>
      )
    }
  ]

  return (
    <div className="layout-header">
      <div className="title">管理后台</div>
      <div className="right">
        <Dropdown
          className="header-menu"
          menu={{ items }}
          placement="bottomRight"
          arrow
          overlayClassName={'header-menu-item'}
        >
          <div className="user">AI</div>
        </Dropdown>
      </div>
    </div>
  )
}

export default memo(LayoutHeader)
